import { gql } from "@apollo/client";
import { useGetRetailersQuery } from "./useGetRetailers.generated";

gql`
  query getRetailers($pageInput: PageInput!) {
    retailers(page: $pageInput) {
      metadata {
        page
        pageSize
        total
      }
      items {
        ...RetailerPreview
      }
    }
  }
`;

export const useGetRetailers = (page: number, pageSize: number) =>
  useGetRetailersQuery({
    variables: { pageInput: { page, pageSize } },
  });

import * as Types from '../../../apiTypes';

import { gql } from '@apollo/client';
import { RetailerPreviewFragmentDoc } from '../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRetailersQueryVariables = Types.Exact<{
  pageInput: Types.PageInput;
}>;


export type GetRetailersQuery = { __typename?: 'Query', retailers: { __typename?: 'RetailerPage', metadata: { __typename?: 'PageMetadata', page: number, pageSize: number, total: number }, items: Array<{ __typename?: 'Retailer', id: string, name: string, status: Types.RetailerStatus, type: Types.RetailerType, region: Types.Region }> } };


export const GetRetailersDocument = gql`
    query getRetailers($pageInput: PageInput!) {
  retailers(page: $pageInput) {
    metadata {
      page
      pageSize
      total
    }
    items {
      ...RetailerPreview
    }
  }
}
    ${RetailerPreviewFragmentDoc}`;

/**
 * __useGetRetailersQuery__
 *
 * To run a query within a React component, call `useGetRetailersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetailersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetailersQuery({
 *   variables: {
 *      pageInput: // value for 'pageInput'
 *   },
 * });
 */
export function useGetRetailersQuery(baseOptions: Apollo.QueryHookOptions<GetRetailersQuery, GetRetailersQueryVariables> & ({ variables: GetRetailersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRetailersQuery, GetRetailersQueryVariables>(GetRetailersDocument, options);
      }
export function useGetRetailersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRetailersQuery, GetRetailersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRetailersQuery, GetRetailersQueryVariables>(GetRetailersDocument, options);
        }
export function useGetRetailersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRetailersQuery, GetRetailersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRetailersQuery, GetRetailersQueryVariables>(GetRetailersDocument, options);
        }
export type GetRetailersQueryHookResult = ReturnType<typeof useGetRetailersQuery>;
export type GetRetailersLazyQueryHookResult = ReturnType<typeof useGetRetailersLazyQuery>;
export type GetRetailersSuspenseQueryHookResult = ReturnType<typeof useGetRetailersSuspenseQuery>;
export type GetRetailersQueryResult = Apollo.QueryResult<GetRetailersQuery, GetRetailersQueryVariables>;